import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "occupancy",
  components: {
    AssetDashboardTab: () => import("@/occupancy/components/DeviceDashboardTab.vue"),
    AssetInspectorStatus: {
      component: () => import("@/components/AssetInspectorStatus.vue"),
      props: {
        itemProperties: ["occ_period_in", "occ_period_out"]
      }
    }
  },
  categoryProperties: {
    in_today: { property: "occ_period_in", unit: "people" },
    out_today: { property: "occ_period_out", unit: "people" },
    total_in: { property: "occ_total_in", unit: "people" },
    total_out: { property: "occ_total_out", unit: "people" }
  },
  properties: {
    occ_period_in: {
      format: "integer",
      unit: "people"
    },
    occ_period_out: {
      format: "integer",
      unit: "people"
    },
    occ_total_in: {
      format: "integer",
      unit: "people"
    },
    occ_total_out: {
      format: "integer",
      unit: "people"
    }
  }
});

export default config;
