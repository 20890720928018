import { computed, ComputedRef, reactive, ref, unref, watch } from "vue";
import { computedWithControl, useMemoize } from "@vueuse/core";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import {
  PropertySelection,
  ChartSeriesDetails,
  AnnotatedChartSeriesGroup,
  MaybeRef,
  TimeSeriesResult,
  Unit,
  PropertySelectionGroup,
  PropertyConfig,
  TimeSeriesConversionFn
} from "@/types";
import { usePropertyTrend } from "@/composables/use-property-trend";
import { usePropertyHistory } from "./use-property-history";
import { useAverageCurrentTrend } from "@/composables/use-average-current-trend";
import { assetParamProvider, convertProperty, destinationUnit } from "@/config/asset";
import { propertyName } from "@/utils/models";
import { selectionKey } from "@/config/asset";
import { transformSeriesData, transformTextualSeriesData } from "@/utils/number";
import { toRawDeep } from "@/utils";
import store from "@/store";

export interface UseGraphDataParams {
  selectionGroups: MaybeRef<PropertySelectionGroup[]>;
  startDate: MaybeRef<DateTime>;
  endDate: MaybeRef<DateTime>;
  aggregate?: MaybeRef<boolean>;
  timeZone?: MaybeRef<string>;
}

export interface UseGraphDataResult {
  groups: ComputedRef<AnnotatedChartSeriesGroup[]>;
  mainGroup: ComputedRef<AnnotatedChartSeriesGroup | null>;
}

export function useGraphData({
  selectionGroups,
  startDate,
  endDate,
  aggregate = false,
  timeZone = store.getters.timeZone
}: UseGraphDataParams): UseGraphDataResult {
  // Assets and properties are regularly updated by subscriptions,
  // but we don't wants graphs to react to those changes right now
  // because they cause excessive rerendering.
  const nonReactiveSelectionGroups = computed(() => toRawDeep(unref(selectionGroups)));

  function _getLineGraphSeries(
    selectionGroup: PropertySelectionGroup,
    selections: PropertySelection[]
  ): ComputedRef<ChartSeriesDetails> {
    const firstSelection = selections[0];
    const queryResult = getTimeSeries(selections, selectionGroup.period);
    const seriesConversionFn = getTimeSeriesConversionFn(selections, firstSelection.property.config);

    const computedSeries = computed<ChartSeriesDetails>(() => {
      return {
        ...queryResult,
        loading: computed(() => queryResult.loading.value || queryResult.series.value === undefined),
        series: computed(() => queryResult.series.value ?? []),
        seriesConversionFn,
        entityName: firstSelection.asset.name,
        propertyName: propertyName(firstSelection.asset, firstSelection.property.name),
        buildingName: firstSelection.asset.building?.name ?? ""
      };
    });

    return computedSeries;
  }

  const getLineGraphSeries = useMemoize(_getLineGraphSeries);

  function getTimeSeries(selections: PropertySelection[], period: number | undefined): TimeSeriesResult {
    const firstSelection = selections[0];
    const property = firstSelection.property;

    if (property.name === "average_energy") {
      const assetUuids = selections.map(s => s.asset.assetUuid);
      return useAverageCurrentTrend(assetUuids, startDate, endDate, {
        period
      });
    }

    if (property.config.dataType === "number") {
      return usePropertyTrend(firstSelection.asset.assetUuid, firstSelection.property, startDate, endDate, period);
    } else {
      return usePropertyHistory(firstSelection.asset.assetUuid, firstSelection.property, startDate, endDate);
    }
  }

  function getTimeSeriesConversionFn(
    selections: PropertySelection[],
    propertyConfig: PropertyConfig
  ): TimeSeriesConversionFn {
    const firstSelection = selections[0];
    const property = firstSelection.property;
    const convertFn = propertyConfig.convertValueFn;
    const convertParams = assetParamProvider(firstSelection.asset);

    if (property.config.dataType === "number") {
      return series => transformSeriesData(series, property, unref(timeZone), convertFn, convertParams);
    } else {
      return series => transformTextualSeriesData(series, property, unref(timeZone));
    }
  }

  function _buildGroup(selectionGroup: PropertySelectionGroup): AnnotatedChartSeriesGroup {
    const initialUnit = unref(selectionGroup).initialUnit ?? undefined;
    const selectedUnit = ref<Unit | null>(initialUnit ?? null);

    if (isNil(selectedUnit.value)) {
      watch(
        () => selectionGroup.selections,
        selections => {
          const firstConfig: PropertyConfig | undefined = selections[0]?.property.config;
          if (firstConfig) {
            const newUnit = destinationUnit(firstConfig);
            if (newUnit) selectedUnit.value = newUnit;
          }
        },
        { immediate: true }
      );
    }

    const convertedSelections = computed<PropertySelection[]>(() => {
      return unref(selectionGroup).selections.map(selection => {
        const convertedProperty = convertProperty(selection.asset, selection.property, unref(selectedUnit));
        return { ...selection, property: convertedProperty };
      });
    });

    const primarySelection = computed(() => {
      return convertedSelections.value[0];
    });

    const primaryProperty = computed(() => {
      return primarySelection.value.property;
    });

    const allSeries = computed(() => {
      if (unref(aggregate)) {
        return [getLineGraphSeries(selectionGroup, convertedSelections.value).value];
      } else {
        return convertedSelections.value.map(s => getLineGraphSeries(selectionGroup, [s]).value);
      }
    });

    const graphOptions = computed(() => {
      const config = primaryProperty.value.config;
      return {
        ...config,
        graphFormat: config.graphFormat ?? config.format
      };
    });

    const result: AnnotatedChartSeriesGroup = reactive({
      seriesDetails: allSeries,
      thresholds: computed(() => primaryProperty.value.thresholdArray),
      selectedUnit,
      graphDataType: computed(() => (primaryProperty.value.config.dataType === "number" ? "number" : "text")),
      graphOptions,
      groupId: computed(() => selectionId(primarySelection.value, selectionGroup.period)),
      primarySelection,
      primaryProperty
    });

    return result;
  }

  const buildGroup = useMemoize(_buildGroup);

  const groups = computedWithControl(
    () => selectionGroupsId(nonReactiveSelectionGroups.value),
    () => {
      const allGroups = nonReactiveSelectionGroups.value.map(selectionGroup => buildGroup(selectionGroup));
      return allGroups;
    }
  );

  return {
    groups,
    mainGroup: computed(() => groups.value[0] ?? null)
  };
}

function selectionGroupsId(groups: PropertySelectionGroup[]) {
  return groups.map(g => selectionGroupId(g)).join("-");
}

function selectionGroupId(group: PropertySelectionGroup) {
  return group.selections.map(s => selectionId(s, group.period)).join("-");
}

function selectionId(selection: PropertySelection, period: number | undefined = undefined) {
  return `${selectionKey(selection)}-${period}`;
}
