import { cloneDeep } from "lodash";
import { computed, getCurrentInstance, reactive, toRefs, watch, ComputedRef, Ref, toRaw, DebuggerOptions } from "vue";

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export function useRouter(): Record<any, any> {
  const vm = (getCurrentInstance() as any).proxy;

  const state = reactive({
    route: vm.$route
  });

  watch(
    () => vm.$route,
    r => {
      state.route = r;
    }
  );

  return { ...toRefs(state), router: vm.$router };
}

export function conditionalRef<T>(
  condition: ComputedRef<boolean>,
  trueResult: Ref<T>,
  falseResult = null
): ComputedRef<T | typeof falseResult> {
  return computed(() => (condition.value ? trueResult.value : falseResult));
}

export function toRawDeep<T>(observed: T): T {
  return toRaw(cloneDeep(observed));
}

export function computedLogger(name: string): DebuggerOptions {
  return {
    onTrack(e) {
      // eslint-disable-next-line no-console
      console.log(`${name} :: onTrack`, e);
    },

    onTrigger(e) {
      // eslint-disable-next-line no-console
      console.log(`${name} :: onTrigger`, e);
    }
  };
}
