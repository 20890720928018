import { isNil, range, toPairs } from "lodash";
import Highcharts from "highcharts";
import { fitBoundsToThresholds, formatNumber, isCategoryFormat } from "@/utils/number";
import { DISABLED_SERIES_COLOR, MAX_SERIES } from "@/config/constants";
import useThresholdRanges from "@/composables/use-threshold-ranges";
import { ChartSeriesDetails, ChartSeriesGroup, TimeSeries, GraphDataType, PointTimeSeries } from "@/types";
import i18n from "@/plugins/i18n";

export interface BuildSeriesOptionsParams {
  group: ChartSeriesGroup;
}

export interface BuildSeriesOptionsResult {
  isComparing: boolean;
  yAxis: Highcharts.YAxisOptions;
  mainSeriesOptions: Highcharts.SeriesOptionsType[];
  navigatorSeriesOptions: Highcharts.NavigatorSeriesOptions[];
  height: number | undefined;
}

interface GraphContext {
  isComparing: boolean;
  isShowingSingleEntity: boolean;
  isShowingSingleProperty: boolean;
  isCategory: boolean;
  hideValueUnit: boolean;
  stepGraph: boolean;
  unitStr: string;
}

interface BoundsOptions {
  softMin?: number;
  softMax?: number;
}

export function buildSeriesOptions({ group }: BuildSeriesOptionsParams): BuildSeriesOptionsResult {
  const { navigatorZones, plotBands } = useThresholdRanges(group.thresholds);
  const graphContext = buildGraphContext(group);

  return {
    isComparing: graphContext.isComparing,
    yAxis: yAxisOptions(group, plotBands.value, graphContext),
    mainSeriesOptions: mainSeriesOptions(group, graphContext),
    navigatorSeriesOptions: navigatorSeriesOptions(group, navigatorZones.value, graphContext),
    height: groupHeight(group)
  };
}

function buildGraphContext(group: ChartSeriesGroup) {
  const isCategory = isCategoryFormat(group.graphOptions?.graphFormat);
  return {
    isComparing: group.seriesDetails.length > 1,
    isShowingSingleEntity: showingSingleEntity(group),
    isShowingSingleProperty: showingSingleProperty(group),
    isCategory,
    hideValueUnit: isCategory ? true : group.graphOptions?.hideValueUnit === true,
    stepGraph: isCategory ? true : group.graphOptions?.stepGraph === true,
    unitStr: formatUnit(group)
  };
}

function yAxisOptions(
  group: ChartSeriesGroup,
  plotBands: Highcharts.YAxisPlotBandsOptions[],
  graphContext: GraphContext
): Highcharts.YAxisOptions {
  const groupValue = group;
  if (groupValue.graphDataType === "number") {
    return numberYAxis(groupValue, plotBands, graphContext);
  } else {
    return textYAxis(groupValue);
  }
}

function numberYAxis(
  group: ChartSeriesGroup,
  plotBands: Highcharts.YAxisPlotBandsOptions[],
  graphContext: GraphContext
): Highcharts.YAxisOptions {
  const baseOptions: Highcharts.YAxisOptions = {
    id: group.groupId,
    title: {
      text: graphContext.unitStr
    },
    plotBands,
    offset: 0
  };
  let yAxisOptions: Highcharts.YAxisOptions;
  if (isCategoryFormat(group.graphOptions?.graphFormat)) {
    yAxisOptions = {
      ...baseOptions,
      tickPositions: [0, 1],
      labels: {
        formatter(context: Highcharts.AxisLabelsFormatterContextObject): string {
          return formatYAxisLabel(context, group);
        }
      }
    };
  } else {
    yAxisOptions = {
      ...baseOptions,
      ...yBounds(group),
      tickAmount: group.graphOptions?.tickAmount,
      tickInterval: group.graphOptions?.tickInterval,
      startOnTick: false,
      endOnTick: false
    };
  }
  return yAxisOptions;
}

function textYAxis(group: ChartSeriesGroup): Highcharts.YAxisOptions {
  return {
    id: group.groupId,
    title: {
      text: group.seriesDetails[0].propertyName,
      rotation: 0,
      textAlign: "right",
      y: 3
    },
    labels: {
      enabled: false
    },
    min: 0,
    max: 100,
    offset: 0,
    gridLineWidth: 0,
    tickAmount: 0
  };
}

function mainSeriesOptions(group: ChartSeriesGroup, graphContext: GraphContext): Highcharts.SeriesOptionsType[] {
  const realSeries = group.seriesDetails.flatMap(s => seriesOptions(group, s, graphContext));
  const placeholderCount = MAX_SERIES - realSeries.length;
  const placeholderSeries = range(placeholderCount).map(i => placeholderSeriesOptions(i));

  return [...realSeries, ...placeholderSeries];
}

function navigatorSeriesOptions(
  group: ChartSeriesGroup,
  navigatorZones: Highcharts.SeriesZonesOptionsObject[],
  graphContext: GraphContext
): Highcharts.NavigatorSeriesOptions[] {
  return group.seriesDetails.flatMap(series => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return seriesPairs(series).map(([_, data]) => {
      return {
        type: "line",
        step: graphContext.stepGraph ? "right" : undefined,
        data: transformPoints(group.graphDataType, data),
        zones: navigatorZones,
        animation: {
          duration: 0
        },
        dataGrouping: {
          enabled: false
        }
      };
    });
  });
}

function groupHeight(group: ChartSeriesGroup): number | undefined {
  if (group.graphDataType === "text") return 5;
  return undefined;
}

function showingSingleEntity(group: ChartSeriesGroup): boolean {
  const [firstSeries, ...otherSeries] = group.seriesDetails;
  return otherSeries.length === 0 || otherSeries.every(s => s.entityName === firstSeries.entityName);
}

function showingSingleProperty(group: ChartSeriesGroup): boolean {
  const [firstSeries, ...otherSeries] = group.seriesDetails;
  return otherSeries.length === 0 || otherSeries.every(s => s.propertyName === firstSeries.propertyName);
}

function formatUnit(group: ChartSeriesGroup): string {
  const unit = group.selectedUnit;
  if (!unit) return "";
  return i18n.t(`units.${unit}`).toString();
}

function yBounds(group: ChartSeriesGroup): BoundsOptions {
  let bounds: { softMin?: number; softMax?: number };
  const { fitBounds, min, max } = group.graphOptions ?? {};

  if (fitBounds && !isNil(min) && !isNil(max)) {
    const [softMin, softMax] = fitBoundsToThresholds(min, max, group.thresholds);
    bounds = {
      softMin,
      softMax
    };
  } else {
    bounds = {
      softMin: min,
      softMax: max
    };
  }

  return bounds;
}

function formatYAxisLabel(context: Highcharts.AxisLabelsFormatterContextObject, group: ChartSeriesGroup): string {
  const value = typeof context.value === "number" ? context.value : parseInt(context.value);
  return formatNumber(value, { format: group.graphOptions?.graphFormat }) ?? "";
}

function seriesOptions(
  group: ChartSeriesGroup,
  series: ChartSeriesDetails,
  graphContext: GraphContext
): Highcharts.SeriesOptionsType[] {
  if (group.graphDataType === "number") {
    return numberSeriesOptions(group, series, graphContext);
  } else {
    return textSeriesOptions(group, series);
  }
}

function numberSeriesOptions(
  group: ChartSeriesGroup,
  series: ChartSeriesDetails,
  graphContext: GraphContext
): Highcharts.SeriesOptionsType[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return seriesPairs(series).map(([_, data]) => {
    const noData = !series.loading && data.length === 0;
    return {
      name: seriesName(series, noData, graphContext),
      type: "line",
      yAxis: group.groupId,
      showInNavigator: false,
      showInLegend: true,
      // If the series has no data, change the color of the symbol in the legend
      color: noData ? DISABLED_SERIES_COLOR : undefined,
      step: graphContext.stepGraph ? "right" : undefined,
      data: transformPoints(group.graphDataType, data),
      dataGrouping: {
        enabled: false
      },
      tooltip: {
        headerFormat: "",
        pointFormatter: function () {
          const seriesName = graphContext.isComparing ? `<b>${this.series.name}</b><br>` : "";
          const formattedDate = Highcharts.dateFormat("%Y-%m-%d %I:%M:%S %p", this.x);
          const value = this.y;
          const formattedValue = formatNumber(value, { format: group.graphOptions?.graphFormat });
          const suffix = graphContext.hideValueUnit ? "" : `${graphContext.unitStr}`;
          return `${formattedDate}<br>${seriesName}<b>${formattedValue}${suffix}</b>`;
        }
      }
    };
  });
}

function textSeriesOptions(group: ChartSeriesGroup, series: ChartSeriesDetails): Highcharts.SeriesOptionsType[] {
  const pointConfig = group.graphOptions?.graphPointConfig;
  if (!pointConfig) return [];

  const options: Highcharts.SeriesOptionsType[] = [];
  for (const [value, data] of seriesPairs(series)) {
    if (!pointConfig[value]) continue;
    options.push({
      name: value,
      type: "area",
      yAxis: group.groupId,
      showInNavigator: false,
      showInLegend: false,
      step: "left",
      color: pointConfig[value].color,
      borderColor: pointConfig[value].color,
      borderWidth: 1,
      lineWidth: 0,
      turboThreshold: 0,
      data: transformPoints(group.graphDataType, data),
      marker: {
        enabled: false
      },
      states: {
        inactive: {
          enabled: false
        }
      },
      dataGrouping: {
        enabled: false
      },
      tooltip: {
        headerFormat: "",
        pointFormatter: function () {
          const seriesName = `<b>${this.series.name}</b><br>`;
          const formattedDate = Highcharts.dateFormat("%Y-%m-%d %I:%M:%S %p", this.x);
          return `${formattedDate}<br>${seriesName}`;
        }
      }
    });
  }

  return options;
}

function seriesName(series: ChartSeriesDetails, noData: boolean, graphContext: GraphContext): string {
  let name = series.entityName;

  if (graphContext.isComparing) {
    if (graphContext.isShowingSingleEntity) {
      name = series.propertyName;
    } else {
      name = graphContext.isShowingSingleProperty
        ? series.entityName
        : `${series.entityName}<br/>${series.propertyName}`;
    }
  }

  const noDataStr = noData ? i18n.t("general.no_data_qualifier") : "";
  return `${name} ${noDataStr}`;
}

function placeholderSeriesOptions(index: number): Highcharts.SeriesOptionsType {
  return {
    name: `placeholder ${index}`,
    type: "line",
    showInNavigator: false,
    showInLegend: false
  };
}

function seriesPairs(series: ChartSeriesDetails): [string, TimeSeries][] {
  return toPairs(series.seriesConversionFn(series.series.value ?? []));
}

function transformPoints(type: GraphDataType, data: TimeSeries): TimeSeries | PointTimeSeries {
  if (type === "number") return data;

  return data.map(([timestamp, value, options]) => {
    let fakeAttrs: Partial<Highcharts.PointOptionsObject> = {};
    if (options?.fake) {
      fakeAttrs = {
        events: {
          mouseOver() {
            return false;
          }
        },
        marker: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      };
    }

    const point: Highcharts.PointOptionsObject = {
      x: timestamp,
      y: value as number | null,
      ...fakeAttrs
    };
    return point;
  });
}
